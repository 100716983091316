import React from "react"
import "./SocialButton.css"

const Social = ({ icon, alt, className }) => {
  return (
    <button className="social-button">
      <img src={icon} className={className} alt={alt} />
    </button>
  )
}

export default Social
