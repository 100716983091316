import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerimage from "../../images/navlogo.svg"
import "./header.css"
import Hamburger from "../Hamburger/Hamburger"

const HeaderNav = ({ setOpen, open }) => {
  const data = useStaticQuery(graphql`
    query NavTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { title } = data.site.siteMetadata

  return (
    <header
      className="header-container header-nav"
      style={{ backgroundColor: "var(--blue-color)" }}
    >
      <div
        className="header-inside"
        style={{
          borderBottom: "1px solid var(--bg-color)",
        }}
      >
        <Link
          to="/"
          style={{
            color: `var(--bg-color)`,
            textDecoration: `none`,
          }}
        >
          <h1 className="header-logo">
            <img src={headerimage} alt="logo" className="logo" />
            {title}
          </h1>
        </Link>
        <Hamburger
          handlerBoolean={false}
          open={open}
          setOpen={setOpen}
          className="hamburger-nav"
        />
      </div>
    </header>
  )
}

HeaderNav.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderNav.defaultProps = {
  siteTitle: ``,
}

export default HeaderNav
