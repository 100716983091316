import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SocialButton from "../Navigation/SocialButton"
import instagram from "../../images/Instagram.svg"
import facebook from "../../images/Facebook.svg"
import phone from "../../images/phone.svg"
import insta_blue from "../../images/insta_blue.svg"
import fb_blue from "../../images/fb_blue.svg"
import phone_blue from "../../images/phone_blue.svg"

import "./SocialIcons.css"
import Anchor from "../anchor"

const SocialIcons = ({ className, blue }) => {
  const data = useStaticQuery(graphql`
    query SocialIcons {
      contactMarkdown: markdownRemark(
        frontmatter: { template: { eq: "contact" } }
      ) {
        frontmatter {
          telephone
          insta_link
          fb_link
        }
      }
    }
  `)

  const { telephone, insta_link, fb_link } = data.contactMarkdown.frontmatter
  return (
    <div className={`social-icons ${className}`}>
      <Anchor anchor={insta_link}>
        <SocialButton icon={blue ? insta_blue : instagram} alt="instagram" />
      </Anchor>
      <Anchor anchor={fb_link}>
        <SocialButton icon={blue ? fb_blue : facebook} alt="facebook" />
      </Anchor>
      <Anchor anchor={`tel:+48${telephone}`}>
        <SocialButton icon={blue ? phone_blue : phone} alt="telefon" />
      </Anchor>
    </div>
  )
}

export default SocialIcons
