import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import HeaderNav from "../header/HeaderNav"
import "./Navigation.css"
import SocialIcons from "../SocialIcons/SocialIcons"

const Navigation = ({ open, setOpen }) => {
  return (
    <div className={`navigation-container ${open && "open"}`}>
      {open && <HeaderNav setOpen={setOpen} open={open} />}
      <div className="spacer"></div>
      <nav className="navigation">
        <ul>
          <li>
            <Link to="/">strona główna</Link>
          </li>
          <li>
            <Link to="/uslugi">usługi</Link>
          </li>
          <li>
            <Link to="/omnie">o mnie</Link>
          </li>
          <li>
            <Link to="/szkolarodzenia">szkoła rodzenia</Link>
          </li>
          <li>
            <Link to="/kontakt">kontakt</Link>
          </li>
          <li>
            <Link to="/blog">blog</Link>
          </li>
        </ul>
      </nav>
      {open && <SocialIcons />}
      <div className="spacer"></div>
    </div>
  )
}

export default Navigation

Navigation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}
