import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import headerimage from "../../images/logo.svg"
import "./header.css"
import Hamburger from "../Hamburger/Hamburger"
import Navigation from "../Navigation/Navigation"

const Header = ({ siteTitle, location }) => {
  const [open, setOpen] = useState(false)
  return (
    <header className="header-container">
      <div className="header-inside">
        <Link
          to="/"
          style={{
            color: `#1f1f1f`,
            textDecoration: `none`,
          }}
        >
          <h1 className="header-logo">
            <img src={headerimage} alt="logo" className="logo" />
            {siteTitle || `szkoła rodzenia "pod sercem"`}
          </h1>
        </Link>
        <Navigation open={open} setOpen={setOpen} location={location} />
        <Hamburger handlerBoolean={true} setOpen={setOpen} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
