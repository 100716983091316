import React from "react"

const Anchor = ({ children, anchor }) => {
  return (
    <a style={{ textDecoration: "none" }} href={anchor}>
      {children}
    </a>
  )
}

export default Anchor
