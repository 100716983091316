import React from "react"
import SocialIcons from "../SocialIcons/SocialIcons"

const Footer = ({ className }) => {
  return (
    <footer className={className}>
      <SocialIcons blue={true} className="footer-icons" />
    </footer>
  )
}

export default Footer
